<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-end mb-4">
        <v-btn
          small
          depressed
          color="fmq_gray"
          dark
          to="/treinamento-criar"
          v-if="solicitacaoCriar && !isColaboradorIc"
          >Nova Solicitação</v-btn
        >
      </div>
      <div class="d-flex align-center justify-start mb-4">
        <div v-for="dado in dadosDictinary" :key="dado.text">
          <v-chip
            :color="dado.color"
            class="ma-2 font-weight-bold"
            text-color="#fff"
            small
            >{{ dados[dado.value] }} - {{ dado.text }}</v-chip
          >
        </div>
      </div>
      <SolicitacoesTabela
        :headers="headers"
        :solicitacoes="item"
        :loading="loading"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        @handleFilter="filter"
        @excluir="excluirModal"
        @iniciarAtendimento="
          dialogAssumir = true;
          itemId = $event.id;
          itemStatusCarta = $event.statusCarta;
        "
        @assumirAtendimento="
          dialogAssumir = true;
          itemId = $event.id;
          itemStatusCarta = $event.statusCarta;
        "
        @encaminhar="
          dialogEncaminhar = true;
          itemId = $event;
        "
      >
        <template v-slot:status>
          <v-autocomplete
            v-model="formData.status"
            :items="listStatus"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="value"
            placeholder="Status"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`colaborador.nome`]>
          <v-autocomplete
            v-model="formData.colaboradorId"
            :items="listColaborador"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Colaborador"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`representante.nome`]>
          <v-autocomplete
            v-model="formData.representanteId"
            :items="listRepresentantes"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Representante"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`produto.nome`]>
          <v-autocomplete
            v-model="formData.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Produtos"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:assunto>
          <v-autocomplete
            v-model="formData.assunto"
            :items="listAssuntos"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="value"
            placeholder="Tema"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:conteudo>
          <v-text-field
            v-model="formData.conteudo"
            placeholder="Conteúdo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscar(formData);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`concorrente.nome`]>
          <v-text-field solo flat dense :hide-details="true" :disabled="true">
          </v-text-field>
        </template>

        <template v-slot:[`representante.gd.nome`]>
          <v-text-field solo flat dense :hide-details="true" :disabled="true">
          </v-text-field>
        </template>

        <template v-slot:createdAt>
          <v-menu
            ref="menuCreatedAt"
            v-model="menuCreatedAt"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datasCreatedAt"
                solo
                flat
                dense
                :hide-details="true"
                readonly
                v-bind="attrs"
                v-on="on"
                :clearable="true"
                placeholder="Aberto em"
                hint="MM/DD/YYYY format"
                @click:clear="
                  page = 1;
                  datasCreatedAt = [];
                  buscar(formData);
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datasCreatedAt"
              range
              no-title
              scrollable
              locale="pt-br"
              :max="nowDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuCreatedAt = false">
                Fechar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  menuCreatedAt = false;
                  page = 1;
                  buscar(formData);
                "
              >
                Selecionar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </template>

        <template v-slot:concluidoEm>
          <v-menu
            ref="menuCreatedAt"
            v-model="menuConcluidoEm"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datasConcluidoEm"
                solo
                flat
                dense
                :hide-details="true"
                readonly
                v-bind="attrs"
                v-on="on"
                :clearable="true"
                placeholder="Finalizado em"
                hint="MM/DD/YYYY format"
                @click:clear="
                  page = 1;
                  datasConcluidoEm = [];
                  buscar(formData);
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datasConcluidoEm"
              range
              no-title
              scrollable
              locale="pt-br"
              :max="nowDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuConcluidoEm = false">
                Fechar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  menuConcluidoEm = false;
                  page = 1;
                  buscar(formData);
                "
              >
                Selecionar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-center">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </SolicitacoesTabela>
    </div>
    <AlertConfirmation
      :dialog="dialog"
      :dialogMessage="dialogMessage"
      @close="dialog = false"
      @accept="excluir"
    />
    <AlertSuccess
      :dialog="success"
      :dialogMessage="dialogMessage"
      @close="
        buscar(formData);
        success = false;
      "
    />
    <AlertConfirmation
      :dialog="dialogAssumir"
      :thirdButton="true"
      dialogMessage="Gostaria de assumir o atendimento desta solicitação?"
      @close="dialogAssumir = false"
      @accept="assumir"
      @thirdEvent="iniciar"
      icon=""
      thirdButtonText="Ir para carta resposta"
    />
    <FormModal
      :loading="loadingEncaminhar"
      :dialog="dialogEncaminhar"
      dialogMessage="Encaminhar solicitação"
      @close="dialogEncaminhar = false"
      @accept="encaminhar"
    >
      <v-form ref="formEncaminhar" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          placeholder="Justificativa para encaminhamento"
          v-model="formDataEncaminhar.comentario"
          required
          :rules="rules.genericRules"
        ></v-textarea>
      </v-form>
    </FormModal>
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
import SolicitacoesTabela from "@/components/solicitacoes/SolicitacoesTreinamentoTabela.vue";
import {
  dadosSolicitacao,
  buscarSolicitacao,
  excluirSolicitacao,
  iniciarAtendimentoSolicitacao,
  encaminharSolicitacao,
} from "@/services/solicitacoes";
import { listFuncionarios, listarRepresentantes } from "@/services/user";
import { listarProdutos } from "@/services/produtos";
export default {
  name: "SolicitacoesTreinamento",
  components: { SolicitacoesTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Solicitações de Treinamento",
        disabled: true,
        to: "",
      },
    ],
    dados: [],
    dadosDictinary: {
      medicosPendente: {
        color: "#f6cf23",
        text: "Em Andamento",
        value: "solicitacoesAndamento",
      },
      solicitacoesAbertas: {
        color: "#40db6e",
        text: "Abertas",
        value: "solicitacoesAbertas",
      },
      solicitacoesRespondido: {
        color: "#ff7900",
        text: "Mensagens recebidas",
        value: "solicitacoesRespondido",
      },
      solicitacoesConsultaInterna: {
        color: "#9f9f9f",
        text: "Consultas interna",
        value: "solicitacoesConsultaInterna",
      },
      solicitacoesEmAprovacao: {
        color: "#993399",
        text: "Em aprovação",
        value: "solicitacoesEmAprovacao",
      },
      solicitacoesAprovada: {
        color: "#00ff00",
        text: "Aprovadas",
        value: "solicitacoesAprovada",
      },
      solicitacoesReprovada: {
        color: "#cf0e0e",
        text: "Precisa de alteração",
        value: "solicitacoesReprovada",
      },
    },
    headers: [
      { text: "Ações", value: "id", sortable: false },
      { text: "Status", value: "status", width: "10px" },
      { text: "Colaborador", value: "colaborador.nome" },
      { text: "Representante", value: "representante.nome" },
      { text: "Produto", value: "produto.nome" },
      { text: "Tema", value: "assunto" },
      { text: "Relato", value: "conteudo", width: "300px" },
      { text: "Concorrente", value: "concorrente.nome", sortable: false },
      {
        text: "Gerente Distrital",
        value: "representante.gd.nome",
        sortable: false,
      },
      { text: "Aberto em", value: "createdAt" },
      { text: "Finalizado em", value: "concluidoEm" },
    ],
    item: [],
    loading: false,
    total: null,
    pageText: null,
    page: 1,
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      id: null,
      mesasId: 2,
      status: "Grupo|Ativa",
      colaboradorId: null,
      representanteId: null,
      produtosId: null,
      assunto: null,
      conteudo: null,
      medicosSolicitantesId: null,
    },
    listStatus: [
      { header: "Status" },
      {
        name: "Em Progresso",
        value: "Grupo|Ativa",
      },
      {
        name: "Todas",
        value: "Grupo|Todas",
      },
      { header: "Solicitação" },
      {
        name: "Aberto",
        value: "Aberto",
      },
      {
        name: "Em andamento",
        value: "Em andamento",
      },
      {
        name: "Troca de mesa",
        value: "Troca de mesa",
      },
      {
        name: "Aguardando resposta",
        value: "Aguardando resposta",
      },
      {
        name: "Consulta interna",
        value: "Consulta interna",
      },
      {
        name: "Respondido",
        value: "Respondido",
      },
      {
        name: "Pendente de aceite",
        value: "Pendente de aceite",
      },
      {
        name: "Em aprovação",
        value: "Carta|Em aprovação",
      },
      {
        name: "Aprovada",
        value: "Carta|Aprovada",
      },
      {
        name: "Reprovada",
        value: "Carta|Reprovada",
      },
      {
        name: "Finalizado",
        value: "Solicitação|Finalizado",
      },
      {
        name: "Pendente para postagem",
        value: "Carta|Aguardando envio",
      },
    ],
    listColaborador: [],
    listRepresentantes: [],
    listProdutos: [],
    listAssuntos: [
      { name: "Anatomia/Fisiologia", value: "Anatomia/Fisiologia" },
      { name: "Patologia", value: "Patologia" },
      { name: "Produto", value: "Produto" },
      { name: "Concorrência", value: "Concorrência" },
      { name: "BVC", value: "BVC" },
      { name: "P&A", value: "P&A" },
      { name: "Material promocional", value: "Material promocional" },
      { name: "Outros", value: "Outros" },
    ],
    menuCreatedAt: false,
    datasCreatedAt: [],
    menuConcluidoEm: false,
    datasConcluidoEm: [],
    nowDate: new Date().toISOString().slice(0, 10),
    dialog: false,
    dialogMessage: null,
    itemId: null,
    error: false,
    messageError: null,
    success: false,
    dialogAssumir: false,
    itemStatusCarta: null,
    rules: rules,
    valid: true,
    dialogEncaminhar: false,
    loadingEncaminhar: false,
    formDataEncaminhar: {
      comentario: null,
    },
    excludeTypePermission: ["Colaborador", "Administrador de mesa"],
  }),
  created() {
    if (
      this.$store.state.user.data.funcionario.mesaId === 1 &&
      this.excludeTypePermission.includes(this.roleUser)
    ) {
      this.$router.push({ path: "/" });
    }
    if (this.$store.state.solicitacoesSearchTr.length) {
      this.formData = { ...this.$store.state.solicitacoesSearchTr[0] };
      if (this.formData.dataCriacaoInicio && this.formData.dataCriacaoFim) {
        this.datasCreatedAt = [
          this.formData.dataCriacaoInicio,
          this.formData.dataCriacaoFim,
        ];
      }
      if (
        this.formData.dataConclusaoInicio &&
        this.formData.dataConclusaoInicio
      )
        this.datasConcluidoEm = [
          this.formData.dataConclusaoInicio,
          this.formData.dataConclusaoFim,
        ];
    }
    this.getColaborador();
    this.getRepresentantes();
    this.getProdutos();
    this.buscar(this.formData);
    this.getDados(2);
  },
  methods: {
    async buscar(payload) {
      if (this.datasCreatedAt.length > 0) {
        payload.dataCriacaoInicio = this.datasCreatedAt[0];
        payload.dataCriacaoFim = this.datasCreatedAt[1];
      }
      if (this.datasConcluidoEm.length > 0) {
        payload.dataConclusaoInicio = this.datasConcluidoEm[0];
        payload.dataConclusaoFim = this.datasConcluidoEm[1];
      }
      this.$store.dispatch("setSolicitacoesSearchTr", payload);
      this.item = [];
      try {
        this.loading = true;
        const resp = await buscarSolicitacao(this.page, payload);
        this.item = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setSolicitacoesSearchTr", {});
      this.formData.status = null;
      this.formData.colaboradorId = null;
      this.formData.produtosId = null;
      this.formData.assunto = null;
      this.formData.conteudo = null;
      this.datasCreatedAt = [];
      delete this.formData.dataCriacaoInicio;
      delete this.formData.dataCriacaoFim;
      this.datasConcluidoEm = [];
      delete this.formData.dataConclusaoInicio;
      delete this.formData.dataConclusaoFim;
      this.page = 1;
      this.buscar(this.formData, this.page);
    },
    search() {
      this.page = 1;
      this.buscar(this.formData, this.page);
    },
    filter(event) {
      this.$store.dispatch("setSolicitacoesSearchTr", this.formData);
      switch (event.orderBy) {
        case "createdAt":
          this.formData.orderBy = "created_at";
          break;
        case "concluidoEm":
          this.formData.orderBy = "concluido_em";
          break;
        default:
          this.formData.orderBy = event.orderBy;
      }
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.buscar(this.formData, this.page);
    },
    async assumir() {
      this.error = false;
      try {
        await iniciarAtendimentoSolicitacao(this.itemId).then(() => {
          this.dialogAssumir = false;
          this.buscar(this.formData);
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async iniciar() {
      this.error = false;
      try {
        await iniciarAtendimentoSolicitacao(this.itemId).then(() => {
          this.$router.push({
            name: this.itemStatusCarta
              ? "CartasRespostaEditar"
              : "CartasRespostaCriar",
            params: { id: this.itemId },
          });
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async encaminhar() {
      if (this.formValidEncaminhar) {
        this.error = false;
        this.sucess = false;
        this.loadingEncaminhar = true;
        try {
          await encaminharSolicitacao(
            this.itemId,
            this.formDataEncaminhar
          ).then(() => {
            this.dialogMessage = "Solicitação encaminhada com sucesso";
            this.dialogEncaminhar = false;
            this.success = true;
          });
          this.loadingEncaminhar = false;
        } catch (e) {
          this.loadingEncaminhar = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirSolicitacao(this.itemId).then(() => {
          this.dialog = false;
          this.dialogMessage = "Solicitação excluida com sucesso";
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir solicitação?`;
      this.dialog = true;
      this.itemId = item.id;
    },
    async getDados(id) {
      const resp = await dadosSolicitacao(id);
      this.dados = resp.data;
    },
    async getColaborador() {
      const resp = await listFuncionarios();
      this.listColaborador = resp.data;
    },
    async getRepresentantes() {
      const resp = await listarRepresentantes();
      this.listRepresentantes = resp.data;
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    validateEncaminhar() {
      this.$refs.formEncaminhar.validate();
    },
  },
  computed: {
    solicitacaoCriar() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.criar"
      );
    },
    formValidEncaminhar() {
      return this.$refs.formEncaminhar.validate();
    },
    isColaboradorIc() {
      if (
        this.$store.state.user.data.tipo === "Colaborador" &&
        this.$store.state.user.data.funcionario.mesaId === 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    roleUser() {
      return this.$store.state.user.data.tipo;
    },
  },
};
</script>

<style></style>
